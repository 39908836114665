<script setup lang="ts">
import { ref, reactive, useTemplateRef } from 'vue';

    const isOpen = ref(false);

    const containerStyles = reactive({
        top: '0'
    });

    const anchor = useTemplateRef('anchor');

    const open = () => {
        const anchorRectangle = anchor.value?.getBoundingClientRect();
        containerStyles.top = `${(anchorRectangle?.height ?? 0)}px`;

        isOpen.value = true;
    };

</script>

<template>

    <div ref="anchor"
        class="dropdown relative"
        @mouseover="open"
        @mouseleave="isOpen = false"
        @click="isOpen = !isOpen"
    >

        <div>
            <slot name="label"></slot>
        </div>

        <div class="dropdown-container"
            :class="{ hidden: !isOpen }"
            :style="containerStyles"
        >
            <div>
                <slot name="content"></slot>
            </div>
        </div>

    </div>

</template>

<style scoped>

    .dropdown-container {

        @apply
            absolute
            top-0 -left-8
            min-h-16
            min-w-16
            px-8 py-4
            shadow-md;

        background-color: #161617CC;

        & > div {
            @apply relative z-20;
        }

    }

    .dropdown-container::before {
        @apply
            z-20
            block
            h-full w-full
            absolute
            top-0 left-0;

        content: "";
        backdrop-filter: blur(15px);
    }

</style>