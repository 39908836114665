<script setup lang="ts">
import { ref } from 'vue';
import FssLogo from '../../components/logo/FssLogo.vue';
import Dropdown from '../../components/dropdown/Dropdown.vue';
import { UserCircleIcon, Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';

const showMobileNav = ref(false);
let cachedOverflowState: string = '';

const toggleMobileNav = (state: boolean) => {
    showMobileNav.value = state;

    if(state === true) {
        cachedOverflowState = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = cachedOverflowState;
    }

};

</script>

<template>

    <!-- Header Nav -->
    <nav v-if="!showMobileNav"
        class="header-nav"
    >
        
        <div class="content-container h-full flex items-center justify-between">
            
            <!-- Site Links -->
            <div class="h-full flex items-center">

                <!-- Home/Logo -->
                <a href="/" class="mr-12">
                    <FssLogo mode="dark" divider="red" class="h-[32px]" />
                </a>

                <a class="hidden sm:inline mr-12" href="/sign-up">Sign Up</a>
                
                <!-- Products -->
                <Dropdown class="hidden sm:flex flex flex-col justify-center h-full">
                    <template v-slot:label>
                        <a class="text-button inline mr-12">Products</a>
                    </template>
                    <template v-slot:content>
                        <a href="/pricing"
                            class="text-button text-white"
                        >Pricing</a>

                        <a href="/features"
                            class="text-button text-white"
                        >Features</a>

                        <a href="/experience"
                            class="text-button text-white"
                        >Experience</a>
                    </template>
                </Dropdown>

                <!-- Resources -->
                <Dropdown class="hidden sm:flex flex flex-col justify-center h-full">
                    <template v-slot:label>
                        <a class="text-button inline mr-12">Resources</a>
                    </template>
                    <template v-slot:content>
                        <a href="/contact"
                            class="text-button text-white"
                        >Contact Us</a>

                        <a href="/payment"
                            class="text-button text-white"
                        >Make a Payment</a>

                        <a href="https://docs.firestationsoftware.com"
                            target="_blank"
                            class="text-button text-white"
                        >Documentation</a>
                    </template>
                </Dropdown>

                <!-- Company -->
                <Dropdown class="hidden sm:flex flex flex-col justify-center h-full">
                    <template v-slot:label>
                        <a class="text-button inline mr-12">Company</a>
                    </template>
                    <template v-slot:content>
                        <a href="/about"
                            class="text-button text-white"
                        >About</a>

                        <a href="/partners"
                            class="text-button text-white"
                        >Partners</a>
                    </template>
                </Dropdown>

            </div>

            <!-- Hub Sign In -->
            <a href="https://id.firestationsoftware.com/login"
                class="hidden sm:flex sm:items-center"
            >

                <UserCircleIcon class="size-6 mr-1" />
                <span class="hidden md:inline ">Sign In</span>

            </a>

            <!-- Mobile Menu -->
            <div @click="toggleMobileNav(true)"
                class="w-10 h-10 flex items-center justify-center sm:hidden"
            >
                <Bars3Icon class="text-white size-6 inline sm:hidden" />
            </div>

        </div>

    </nav>

    <!-- Mobile Nav -->
    <nav v-if="showMobileNav"
        class="mobile-nav"
    >

        <!-- Header -->
        <div class="mobile-nav-header">

            <!-- Home/Logo -->
            <a href="/"
                @click="toggleMobileNav(false)"
            >
                <FssLogo mode="dark" divider="red" class="h-[32px]" />
            </a>

            <!-- Close Menu -->
            <div @click="toggleMobileNav(false)"
                class="w-10 h-10 flex items-center justify-center"
            >
                <XMarkIcon class="text-white size-6" />
            </div>

        </div>

        <!-- Content -->
        <div class="mobile-nav-content">

            <!-- Products -->
            <h2>Products</h2>

            <a href="/pricing"
                @click="toggleMobileNav(false)"
                class="text-button text-white"
            >Pricing</a>

            <a href="/features"
                @click="toggleMobileNav(false)"
                class="text-button text-white"
            >Features</a>

            <a href="/experience"
                @click="toggleMobileNav(false)"
                class="text-button text-white"
            >Experience</a>

            <!-- Resources -->
            <h2>Resources</h2>

            <a href="/contact"
                @click="toggleMobileNav(false)"
                class="text-button text-white"
            >Contact Us</a>

            <a href="/payment"
                @click="toggleMobileNav(false)"
                class="text-button text-white"
            >Make a Payment</a>

            <a href="https://docs.firestationsoftware.com"
                target="_blank"
                @click="toggleMobileNav(false)"
                class="text-button text-white"
            >Documentation</a>

            <!-- Company -->
            <h2>Company</h2>

            <a href="/about"
                @click="toggleMobileNav(false)"
                class="text-button text-white"
            >About</a>

            <a href="/partners"
                @click="toggleMobileNav(false)"
                class="text-button text-white"
            >Partners</a>

            <!-- Sign Up -->
            <a href="/sign-up"
                @click="toggleMobileNav(false)"
                class="primary w-full mt-8 flex items-center justify-center"
            >Sign up for Free</a>

            <!-- Hub Sign In -->
            <a href="https://id.firestationsoftware.com/login"
                @click="toggleMobileNav(false)"
                class="fancy-inverted white shadow-md w-full mt-6 flex items-center justify-center"
            >
                <UserCircleIcon class="size-6 mr-1" />
                <span>Sign In</span>
            </a>

        </div>

    </nav>

    <!-- Content -->
    <main>
        <slot />
    </main>

    <!-- Footer -->
    <footer>
        <div class="content-container">
            <div class="flex flex-wrap sm:flex-nowrap">
                <div class="mb-4">
                    <FssLogo class="h-[32px] mb-4" />
                    <div class="flex flex-col mb-4">
                        <span class="font-medium">Fire Station Software, LLC</span>
                        <span>10354 W. Chatfield Avenue</span>
                        <span>Suite 106</span>
                        <span>Littleton, CO 80127</span>
                    </div>
                    <div class="contact">
                        Call us at <a href="tel:+18888092673">(888) 809-2673</a> <br />
                        or email <a href="mailto:sales@firestationsoftware.com">sales@firestationsoftware.com</a>
                    </div>
                    <div class="contact mt-2">
                        Visit us on <a href="https://www.facebook.com/firestationsoftware/">facebook</a> too!
                    </div>
                </div>
                <div class="flex mb-4">
                    <div class="flex flex-col mr-4 sm:mr-8">

                        <!-- Products -->
                        <h3>Products</h3>

                        <a href="/pricing"
                            class="text-button small"
                        >Pricing</a>

                        <a href="/features"
                            class="text-button small"
                        >Features</a>

                        <a href="/experience"
                            class="text-button small"
                        >Experience</a>

                    </div>
                    <div class="flex flex-col mr-8">

                        <!-- Resources -->
                        <h3>Resources</h3>

                        <a href="/contact"
                            class="text-button small"
                        >Contact Us</a>
                        
                        <a href="/payment"
                            class="text-button small"
                        >Make a Payment</a>
                        
                        <a href="https://docs.firestationsoftware.com"
                            target="_blank"
                            class="text-button small"
                        >Documentation</a>

                    </div>
                    <div class="flex flex-col mr-8">

                        <!-- Company -->
                        <h3>Company</h3>
                        
                        <a href="/about"
                            class="text-button small"
                        >About</a>
                        
                        <a href="/partners"
                            class="text-button small"
                        >Partners</a>

                    </div>
                </div>
            </div>
            <div class="links">

                <!-- Terms of Service -->
                <a href="/terms-of-service"
                    class="text-button small inline"
                >Terms of Service</a>

                <!-- Privacy Policy -->
                <a href="/privacy-policy"
                    class="text-button small inline"
                >Privacy Policy</a>

            </div>
        </div>
    </footer>

</template>

<style scoped>

    .header-nav {
        @apply
            h-16
            fixed
            z-20
            top-0 left-0 right-0
            flex items-center justify-center;
        
        background-color: #161617CC;

        & > div {
            @apply z-50;
        }

        & a {
            @apply text-white text-base;
        }
    }

    .header-nav::before {
        @apply
            z-20
            block
            h-full w-full
            absolute
            top-0 left-0;

        content: "";
        backdrop-filter: blur(15px);
    }

    .mobile-nav {
        @apply
            h-full
            w-full
            m-0
            fixed
            top-0 left-0
            flex flex-col
            overflow-y-auto;
        
        background-color: #161617CC;
        backdrop-filter: blur(15px);

        & a.text-button {
            @apply pl-4;
        }

        & h2 {
            @apply
                text-white
                text-xs
                mt-6
                mb-4;
        }
    }

    .mobile-nav > .mobile-nav-header {
        @apply
            h-16
            mx-4
            grow-0
            shrink-0
            flex
            items-center justify-between;
    }

    .mobile-nav > .mobile-nav-content {
        @apply
            grow
            p-4;
    }

    footer {
        @apply
            bg-neutral-100
            py-8
            text-xs
            text-neutral-600;

        & a {
            @apply text-neutral-600;
        }

        & .contact > a {
            @apply text-fss-brand-red;
        }

        & h3 {
            @apply
                text-neutral-700
                font-medium
                mb-4;
        }

        & .links {
            @apply
                pt-4
                border-t border-t-neutral-300;

            & > a:not(:first-child):before {
                @apply mx-2;

                content: "|";
            }
        }
    }

</style>